export default defineNuxtRouteMiddleware((to) => {
  const { isAuthenticated } = useAuth()
  const publicRoutes = ['/', '/login']

  if (!isAuthenticated.value && !publicRoutes.includes(to.path)) {
    return navigateTo({
      path: '/login',
      query: { redirect: to.fullPath }
    })
  }
}) 